import { h } from 'preact';
import { useMemo } from 'preact/hooks';

import { Footer } from '~/components/Footer';

import { useTranslations } from './i18n/useTranslations';
import * as styles from './styles.css';

const DEFAULT_LOCALE = process.env.DEFAULT_LANG;
const SCOPE = process.env.PREFIX.toLowerCase();

const MainContainer = (props) => {
  const { children, locale = DEFAULT_LOCALE } = props;

  const { i18n } = useTranslations(locale);

  const texts = useMemo(() => {
    if (i18n && SCOPE) {
      return i18n[SCOPE];
    }

    return null;
  }, [i18n]);

  if (!texts) {
    return null;
  }

  return (
    <section className={styles.container}>
      {children}

      <Footer texts={texts} />
    </section>
  );
};

export default MainContainer;
