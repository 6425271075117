import { loadScript } from '~/utils/loadScript';

import libPath from './lib.min';

// @see ./webpack.common.js

const GIB_CID = process.env.GIB_CID;
const GIB_BACK_URL = process.env.GIB_BACK_URL;

const executeOnReady = (func) => {
  if (window.gib) {
    func();

    return;
  }

  const currentHandler = window.onGIBLoad ?? (() => null);

  window.onGIBLoad = () => {
    currentHandler();
    func();
  };
};

let instance = null;

export class Gib {
  constructor() {
    if (!GIB_CID || !GIB_BACK_URL) {
      return null;
    }

    if (instance) {
      return instance;
    }

    executeOnReady(() => {
      window.gib.init({
        cid: GIB_CID,
        backUrl: GIB_BACK_URL,
      });
    });

    loadScript(libPath);
  }

  setAttribute(name, value) {
    executeOnReady(() => {
      window.gib.setAttribute(name, value);
    });
  }

  setIdentity(userId) {
    executeOnReady(() => {
      window.gib.setAuthStatus(window.gib.IS_AUTHORIZED);
      window.gib.setIdentity(userId);
    });
  }

  setSessionID(id) {
    executeOnReady(() => {
      window.gib.setSessionID(id);
    });
  }
}
